<template>
  <div class="project_header" ref="project_header">
    <div class="header_bgImg">
      <!-- 其他页面头部展示 -->
      <div class="noIndexPageImg" :style="{ height: typeHeight + 'px' }" v-if="typeHeader == 'defaultHeader'">
        <div class="noIndexShow">
          <!-- banner -->
          <slot name="headerImage"></slot>
          <!-- <img src="https://pic.imgdb.cn/item/615407e22ab3f51d910fde2c.jpg" alt=""> -->
          <div class="black_div">
            <!-- 标题 -->
            <span data-aos="fade-up">
              <slot name="headerTitle"></slot>
            </span>
          </div>
        </div>
      </div>

      <!-- 首页头部展示 -->
      <div class="IndexPageImg" :style="'height:' + lbtH + ';'" v-else-if="typeHeader == 'indexHeader'">
        <div class="IndexShow">
          <el-carousel :height="lbtH" arrow="always" :initial-index="lbtIs_show" @change="lbtChange">
            <el-carousel-item>
              <img v-if="$store.state.navShow == true" src="../assets/header/guojigongyinglian.jpg" alt="图片加载失败" />
              <img v-else src="../assets/header/guojigongyinglianE.jpg" alt="图片加载失败" />
            </el-carousel-item>
            <el-carousel-item>
              <img  v-if="$store.state.navShow == true" src="../assets/header/pc.jpg" alt="图片加载失败" @click="pcImg" />
              <img v-else src="../assets/header/pcE.jpg" alt="图片加载失败" @click="pcImg" />
            </el-carousel-item>
            <!-- <el-carousel-item>
              <img  v-if="$store.state.navShow == true" src="../assets/header/banner2.jpg" alt="图片加载失败" />
              <img v-else  src="../assets/header/banner2E.jpg" alt="图片加载失败" />
            </el-carousel-item> -->
            <el-carousel-item>
              <img  v-if="$store.state.navShow == true" src="../assets/header/banner3.jpg" alt="图片加载失败" />
              <img v-else src="../assets/header/banner3E.jpg" alt="图片加载失败" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 导航 -->
      <div :class="['navHeader', pageScrollTop !== 0 ? 'navBg' : '']">
        <div class="nav_main">
          <div class="nav_left" data-aos="fade-right" data-aos-duration="1000" @click="$router.push('/index')">
            <img src="../assets/header/logo2.png" alt="" v-if="pageScrollTop !== 0" />
            <img src="../assets/header/logo2.png" v-else />
          </div>
          <div class="nav_right">
            <div class="nav_items">
              <el-menu :default-active="activeIndex" class="el-menu-demo el-menuMe" mode="horizontal"
                @select="handleSelect">
                <fragment v-for="(item, key) in navList" :key="key">
                  <template v-if="item.hidden">
                    <el-submenu  :index="jisuan([key + 1])" v-if="item.children">
                      <template slot="title">{{ $store.state.navShow == true ? item.name : item.EnName }}</template>
                      <fragment  v-for="(Child_item, Child_index) in item.children" :key="Child_index">
                        <!-- <template v-if="!Child_item.meta.is_showPages"> -->
                        <el-submenu :index="jisuan([key + 1, Child_index + 1])" v-if="Child_item.children">
                          <template slot="title" >{{
                            $store.state.navShow == true ? Child_item.name : Child_item.EnName
                          }}</template>

                          <fragment v-for="(
                              child_three, child_three_index
                            ) in Child_item.children" :key="child_three_index">
                            <el-menu-item @click="jumpUrlFun(child_three.path)" :index="
                                jisuan([
                                  key + 1,
                                  Child_index + 1,
                                  child_three_index + 1,
                                ])
                              ">{{ $store.state.navShow == true ? child_three.name : child_three.EnName }}</el-menu-item>
                          </fragment>
                          <!-- <el-menu-item index="2-4-2">选项2</el-menu-item>
                                                        <el-menu-item index="2-4-3">选项3</el-menu-item> -->
                        </el-submenu>
                        <el-menu-item @click="jumpUrlFun(Child_item.path)" :index="jisuan([key + 1, Child_index + 1])"
                          v-else>{{$store.state.navShow == true ? Child_item.name : Child_item.EnName }}</el-menu-item>
                        <!-- </template> -->
                      </fragment>
                    </el-submenu>

                    <el-menu-item :index="jisuan([key + 1])" @click="jumpUrlFun(item.path)" v-else>
                      {{ $store.state.navShow == true ? item.name : item.EnName }}
                    </el-menu-item>
                  </template>
                </fragment>

                <!-- <el-menu-item index="1">首页</el-menu-item>
                                <el-menu-item>关于我们</el-menu-item>
                                <el-submenu >
                                    <template slot="title">我们的服务</template>
                                    <el-menu-item index="2-1">中欧运输</el-menu-item>
                                     <el-submenu>
                                        <template slot="title">中俄物流</template>
                                        <el-menu-item>中俄铁路</el-menu-item>
                                        <el-menu-item >选项3</el-menu-item>
                                    </el-submenu>

                                    <el-menu-item>海外仓</el-menu-item>

                                </el-submenu>
                                <el-menu-item index="4" >货运追踪</el-menu-item>
                                <el-menu-item index="5"><a href="https://www.ele.me" target="_blank">联系我们</a></el-menu-item> -->
              </el-menu>
              <div class="line"></div>
            </div>

            <!-- 官网图标按钮 -->
            <div class="websiteIconBtn" @click="els_web_siteClick" >
              <el-button type="primary" class="elButt">
                {{ $store.state.navShow == true ? 'Cn' : 'En' }}
                <!-- <div class="iconIMg">
                  <img src="../assets/header/eluosi.png" alt="" />
                </div>
                俄罗斯官网 -->
              </el-button>
            </div>
          </div>
        </div>
      </div>

      <!-- 页面大标题 -->
      <div class="pages_title_range">
        <!-- 非首页标题 -->
        <div class="pages_title" v-if="typeHeader == 'defaultHeader'">
          <div class="pages_title_l">
            <!-- 名称 -->
            <h1 v-if="pageType == 'otherPages'">{{ $store.state.navShow == true ? $route.name : $route.meta.EnName }}</h1>
            <h1 v-else-if="pageType == 'newsDetail'">
              {{ $store.state.navShow == true ? $route.matched[1].name :  $route.meta.EnName }}
            </h1>
          </div>
          <div class="pages_title_r">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="breadCrumb">
              <fragment v-for="(item, index) in nowCLickUrlArr" :key="index">
                <!-- routeIsNews==true新闻中心页  routeIsNews==false(走默认值)走其他页面-->
                <el-breadcrumb-item v-if="!(routeIsNews && nowCLickUrlArr.length - 1 == index)">
                  <router-link :to="'/index'" v-if="index == 0">
                    <!-- <img src="../assets/home.png" alt="" class="homeImg"> -->
                    <i class="el-icon-s-home"></i>
                    {{ $store.state.navShow == true ?  item.name : item.meta.EnName}}
                  </router-link>
                  <router-link v-else :to="item.path">
                    <!-- 新闻详情的情况  -->
                    <span v-if="
                        pageType == 'newsDetail' &&
                        nowCLickUrlArr.length - 1 == index
                      ">{{ newsTitleRoute }}</span>
                    <span v-else>{{ $store.state.navShow == true ?  item.name : item.meta.EnName }}</span>
                  </router-link>
                </el-breadcrumb-item>
              </fragment>

              <!-- <el-breadcrumb-item>活动列表</el-breadcrumb-item>
                            <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
            </el-breadcrumb>
          </div>
        </div>

        <!-- 首页标题 -->
        <div class="index_title" v-else-if="typeHeader == 'indexHeader'">
          <el-carousel height="154px;" :autoplay="false" arrow="never" indicator-position="none"
            :initial-index="lbtIs_show" ref="squareLbt">
            <el-carousel-item>
              <div class="index_title_items1" v-for="(item, index) in  $store.state.navShow == true ? indexHeaderSquareImg1:indexHeaderSquareImg1E" :key="index"
                @click="indexSquareClick(item.jumpPageUrl)">
                <img :src="item.squareImg" alt="" />
              </div>
            </el-carousel-item>

            <el-carousel-item style="display: none">
              <div class="index_title_items2" v-for="(item, index) in indexHeaderSquareImg2" :key="index"
                @click="indexSquareClick2(item.jumpUrl)">
                <img :src="item.img" alt="" />
              </div>
            </el-carousel-item>
            <!-- <el-carousel-item>
              <div class="index_title_items2" v-for="(item, index) in $store.state.navShow == true ?  indexHeaderSquareImg2 :indexHeaderSquareImg2E" :key="index"
                @click="indexSquareClick2(item.jumpUrl)">
                <img :src="item.img" alt="" />
              </div>
            </el-carousel-item> -->
            <el-carousel-item>
              <div class="index_title_items3" v-for="(item, index) in $store.state.navShow == true ? indexHeaderSquareImg3 :indexHeaderSquareImg3E" :key="index">
                <img :src="item.img" alt="" />
              </div>
            </el-carousel-item>
          </el-carousel>

          <!-- 首页第一张banner -->
          <!-- <template v-if="lbtIs_show==0">
                        <div
                            class="index_title_items1"
                            v-for="(item,index) in indexHeaderSquareImg1"
                            :key="index"
                            @click="indexSquareClick(item.jumpPageUrl)"
                        >
                            <img :src="item.squareImg" alt="">
                        </div>
                    </template>
                    -->
          <!-- <div class="index_title_items">
                        <img src="../assets/header/home2.png" alt="">
                    </div>
                    -->

          <!-- 首页第二张banner -->
          <!-- <template v-if="lbtIs_show==1">
                        <div
                            class="index_title_items2"
                            v-for="(item,index) in indexHeaderSquareImg2"
                            :key="index"

                        >
                            <img :src="item.img">
                        </div>
                    </template> -->

          <!-- 首页第三张banner -->
          <!-- <template v-if="lbtIs_show==2">
                        <div
                            class="index_title_items3"
                            v-for="(item,index) in indexHeaderSquareImg3"
                            :key="index"
                        >
                            <img :src="item.img">
                        </div>
                    </template> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      lbtH: '', // 轮播图高
      lbtArr: [], // 轮播图数组
      elsWeb: '', // 俄罗斯官网

      navList: [], // 导航的list
      activeIndex: '1', // 导航展示的选中的下标
      nowCLickUrlArr: [], // 存放当前点击页面的数组
      pageScrollTop: window.pageYOffset, // 页面滚动的距离 默认是0

      lbtIs_show: 0, // 控制首页轮播图

      indexHeaderSquareImg1: [{
        squareImg: require('../assets/header/home1.png'),
        jumpPageUrl: '/chinaEurope_train' // 中欧运输(中欧班列)页面
      },
      {
        squareImg: require('../assets/header/home2.png'),
        jumpPageUrl: '/international_sea' // 海运服务(国际服务)页面
      },
      {
        squareImg: require('../assets/header/home3.png'),
        jumpPageUrl: '/international_car' // 汽车运输(国际汽运)页面
      },
      {
        squareImg: require('../assets/header/home4.png'),
        jumpPageUrl: '/airServices' // 空运服务
      },
      {
        squareImg: require('../assets/header/home5.png'),
        jumpPageUrl: '/produetsEQingServices' // 清关服务
      },
      {
        squareImg: require('../assets/header/home6.png'),
        jumpPageUrl: '/international_trade' // 国际贸易
      }
      ],
      indexHeaderSquareImg1E: [{
        squareImg: require('../assets/header/home1E.png'),
        jumpPageUrl: '/chinaEurope_train' // 中欧运输(中欧班列)页面
      },
      {
        squareImg: require('../assets/header/home2E.png'),
        jumpPageUrl: '/international_sea' // 海运服务(国际服务)页面
      },
      {
        squareImg: require('../assets/header/home3E.png'),
        jumpPageUrl: '/international_car' // 汽车运输(国际汽运)页面
      },
      {
        squareImg: require('../assets/header/home4E.png'),
        jumpPageUrl: '/airServices' // 空运服务
      },
      {
        squareImg: require('../assets/header/home5E.png'),
        jumpPageUrl: '/produetsEQingServices' // 清关服务
      },
      {
        squareImg: require('../assets/header/home6E.png'),
        jumpPageUrl: '/international_trade' // 国际贸易
      }
      ],

      indexHeaderSquareImg2: [
        // 首页第二张大图
        {
          img: require('../assets/header/lianrencaijidi.png')
        },
        // {
        //   img: require('../assets/header/huanqiukejichuangxin.png'),
        //   jumpUrl: 'http://www.innohebei.net'
        // },
        {
          img: require('../assets/header/haiwaichuangxinzhongxin.png')
        },
        {
          img: require('../assets/header/InnoHouse.png')
        }
      ],

      indexHeaderSquareImg2E: [
        // 首页第二张大图
        {
          img: require('../assets/header/lianrencaijidiE.png')
        },
        // {
        //   img: require('../assets/header/huanqiukejichuangxinE.png'),
        //   jumpUrl: 'http://www.innohebei.net'
        // },
        {
          img: require('../assets/header/haiwaichuangxinzhongxinE.png')
        },
        {
          img: require('../assets/header/InnoHouseE.png')
        }
      ],

      indexHeaderSquareImg3: [
        // 首页第二张大图
        {
          img: require('../assets/header/gongcheng.png')
        },
        {
          img: require('../assets/header/dichan.png')
        },
        {
          img: require('../assets/header/tanjiaoyi.png')
        }
      ],
      indexHeaderSquareImg3E: [
        // 首页第二张大图
        {
          img: require('../assets/header/gongchengE.png')
        },
        {
          img: require('../assets/header/dichanE.png')
        },
        {
          img: require('../assets/header/tanjiaoyiE.png')
        }
      ]
    }
  },
  props: {
    // 首页类型
    typeHeader: {
      type: String,
      default: 'defaultHeader'
    },
    typeHeight: {
      type: String,
      default: '480' //  480
    },
    pageType: {
      type: String,
      default: 'otherPages'
    },
    // 新闻标题
    newsTitleRoute: {
      type: String,
      default: ''
    },
    // 新闻中心标题
    routeIsNews: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    jisuan () {
      return (numArr) => {
        let ss = ''
        numArr.forEach((item) => {
          ss += `${String(item)}-`
        })
        return ss.slice(0, -1)
      }
    }
  },
  mounted () {
    console.log('9999999999999', this.$route)
    // 获取到导航List
    this.navList = this.$router.options.routes[0].children

    // 获取到当前点击得页面
    this.activeIndex = this.$route.meta.activeIndex

    // 当前页面
    this.nowCLickUrlArr = this.$route.matched
    console.log('当前页面', this.$route.matched)
    // 监听滚动事件
    window.addEventListener(
      'scroll',
      (e) => {
        this.pageScrollTop = window.pageYOffset
      },
      true
    )

    // 设备宽 * 原图片高 / 原图片�� �
    this.lbtH = window.visualViewport.width * 700 / 1920 + 'px'

    /**
       * 首页轮播图
       * 肖明霞
       * 2021-11-22
       */
    if (this.typeHeader === 'indexHeader') {
      this.$axios.get('/api/Index/bannerList').then((res) => {
        // console.log('res=>',res.data);
        if (res.data.code === 1) {
          this.lbtArr = res.data.data.map((item) => {
            // console.log('首页轮播图=>',item)
            return item
          })
        } else {
          this.$message({
            showClose: true,
            message: '首页轮播图' + res.data.msg,
            type: 'error'
          })
        }
      })
    }

    /**
       * 底部数据 接口
       * 肖明霞
       * 2021-11-22
       */
    this.$axios.get('/api/Index/footer').then((res) => {
      console.log('底部数据=>', res)
      if (res.data.code === 1) {
        // 俄罗斯官网
        this.elsWeb = res.data.data.footerConfig.els_web_site
      } else {
        this.$message({
          showClose: true,
          message: '俄罗斯官网' + res.data.msg,
          type: 'error'
        })
      }
    })

    /**
       * 首页，关于我们，货运追踪 seo
       * 肖明霞
       * 2021-11-23
       */
    if (this.$route.meta.seo === 'defaultSeo') {
      // 调用接口地址
      this.$axios.get(this.$route.meta.seoRequestUrl).then((res) => {
        // console.log('seo',res.data.data);
        if (res.data.code === 1) {
          this.updateSeoFun(res.data.data)
        } else {
          this.$message({
            showClose: true,
            message: this.$route.name + 'seo,' + res.data.msg,
            type: 'error'
          })
        }
      })
    }

    /**
       * 联系我们seo
       * 肖明霞
       * 2021-11-23
       */
    if (this.$route.meta.seo === 'contactUsSeo') {
      this.$axios.get('/api/Contactus/index').then((result) => {
        if (result.data.code === 1) {
          this.updateSeoFun(result.data.data.seo)
        } else {
          this.$message({
            showClose: true,
            message: this.$route.name + 'seo,' + result.data.msg,
            type: 'error'
          })
        }
      })
    }

    /**
       * 我们的服务 seo
       * 肖明霞
       * 2021-11-23
       */
    if (this.$route.meta.seo === 'serveSeo') {
      this.$axios
        .get('/api/Ourservices/index', {
          params: {
            catid: this.$route.meta.seoId
          }
        })
        .then((res) => {
          // console.log('我们服务',res)
          if (res.data.code === 1) {
            this.updateSeoFun(res.data.data)
          } else {
            this.$message({
              showClose: true,
              message: this.$route.name + 'seo,' + res.data.msg,
              type: 'error'
            })
          }
        })
    }

    // this.indexSeoFun()
  },
  watch: {},
  methods: {
    /**
       * banner 点击事件
       * 肖明霞
       * 2022-01-07
       */
    pcImg () {
      this.$router.push('/businessServices')
    },

    /**
       * 首页seo
       */
    // indexSeoFun(){
    //     this.$axios.get('/api/Index/index').then(res => {
    //         console.log('seo',res.data.data);
    //
    //     })
    // },

    /**
       * 更新页面的seo函数
       * 肖明霞
       * 2021-11-23
       */
    updateSeoFun (options) {
      // 标题
      document.title = options.title
      const AllMeta = document.getElementsByTagName('meta')

      for (var i = 0; i < AllMeta.length; i++) {
        // keywords
        if (AllMeta[i].getAttribute('name') === 'keywords') {
          AllMeta[i].content = options.keywords
        }
        // description
        if (AllMeta[i].getAttribute('name') === 'description') {
          AllMeta[i].content = options.description
        }
      }
    },

    /**
       *  轮播图切换时的 change 事件
       *  肖明霞
       *  2021-12-02
       */
    lbtChange (e) {
      console.log('轮播图change=>', e)
      this.lbtIs_show = Number(e)
      // console.log('this.lbtIs_show',this.lbtIs_show)
      this.$refs.squareLbt.setActiveItem(e)
    },

    /**
       * 俄罗斯官网点击事件
       * 肖明霞
       * 2021-11-22
       */
    els_web_siteClick () {
      this.$store.state.navShow = !this.$store.state.navShow
      // console.log(this.$children.abcde())
      // this.$children
      console.log(this)
      // console.log(this.$parent.abcde());
      //  this.$parent.abcde();
      //  this.$emit('abcde')
    // this.abcde();
      // window.open(this.elsWeb, '_blank')
    },

    /**
       * 首页轮播图点击事件 (外链地址)
       * 肖明霞
       * 2021-11-22
       */
    lbtItemClick (e) {
      if (e !== '') {
        window.open(e, '_blank')
      }
    },

    handleSelect (e) {
      this.activeIndex = e
    },

    /**
       * 每个页面导航的跳转方式路径
       * 肖明霞
       * 2021-11-30
       */
    jumpUrlFun (path) {
      this.$router.push(path)
    },
    /**
       * 首页头部下边的7个方块跳转点击事件
       * 肖明霞
       * 2021-11-30
       */
    indexSquareClick (jumpPath) {
      this.$router.push(jumpPath)
    },
    /**
       * 首页头部下边的4个方块跳转点击事件
       * 肖明霞
       * 2021-12-01
       */
    indexSquareClick2 (url) {
      console.log('url=>', url)
      if (url !== undefined) {
        window.open(url, '_blank')
      }
    }
  }
}
</script>
<style lang="less">
  // 导航背景为白色得情况 也就是需要滚动时得导航栏样式
  .navBg {
    background: #fff;

    & .el-menu-item {
      color: #333333 !important;
    }

    & .el-submenu__title {
      color: #333333 !important;

      &>i {
        color: #333333 !important;
      }
    }

    & .el-menu--horizontal>.el-menu-item.is-active {
      border-top: 3px solid #2e67b1 !important;
      color: #2e67b1 !important;
      font-weight: 900;
    }

    .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
      border-top: 3px solid #2e67b1 !important;
    }

    & .el-menu--horizontal>.el-menu-item {
      border: none !important;
      border-top: 3px solid transparent !important;
    }

    & .el-menu--horizontal>.el-submenu .el-submenu__title {
      border: none !important;
      border-top: 3px solid transparent !important;
    }
  }

  //  导航
  .project_header {
    width: 100%;
    background: rgb(180, 172, 172);

    .header_bgImg {
      width: 100%;
      // height:480px;
      position: relative;

      // 非首页显示得图片banner
      .noIndexPageImg {
        width: 100%;
        // height:480px;
        position: relative;

        // background: red;
        .noIndexShow {
          width: 100%;
          height: 100%;
          position: relative;

          // background: orange;
          img {
            width: 100%;
            height: 100%;
          }

          .black_div {
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0);
            position: absolute;
            top: 0;
            left: 0;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;

            h1 {
              font-family: Microsoft YaHei;
              display: flex;
              flex-direction: column;
              align-items: center;

              img {
                width: 482px;
                height: 53px;
                margin-bottom: 16px;
              }
            }
          }
        }
      }

      // 首页显示的轮播图
      .IndexPageImg {
        width: 100%;
        // height: 694px;
        // position: relative;
        // background: red;
        cursor: pointer;

        .IndexShow {
          & img {
            width: 100%;
            height: 100%;
          }

          .itemLbt {
            width: 100%;
            height: 100%;

            &>img {
              width: 100%;
              height: 100%;
            }

            // background: purple;
            .black_divIndex {
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.2);
              position: absolute;
              top: 0;
              left: 0;
              z-index: 3;
              font-size: 36px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          @keyframes opacityAnimation {
            0% {
              background: rgba(52, 61, 76, 0.1);
            }

            // 50%{
            //     background: rgba(52, 61, 76, 0.5);
            // }
            100% {
              background: rgba(52, 61, 76, 1);
            }
          }

          .el-carousel__arrow--left {
            animation: opacityAnimation 1.5s infinite alternate;
            width: 42px;
            height: 42px;
          }

          .el-carousel__arrow--right {
            animation: opacityAnimation 1.5s infinite alternate;
            width: 42px;
            height: 42px;
          }

          .el-icon-arrow-left:before,
          .el-icon-arrow-right:before {
            font-size: 21px;
            font-weight: bold;
          }

          // 指示器
          .el-carousel__indicator--horizontal {
            padding: 120px 10px;
          }

          .el-carousel__button {
            width: 50px;
            height: 6px;
            border-radius: 5px;
          }
        }
      }

      // 新闻中心显示图片
      .newsImg {
        width: 100%;
        height: 350px;
      }

      .navHeader {
        width: 100%;
        height: 95px;
        // background: orange;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;

        .nav_main {
          width: 1200px;
          height: 100%;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;

          .nav_left {
            // flex: 1;
            // background: pink;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
              width: auto;
              height: 70px;
              transform: scale(0.7);
            }
          }

          .nav_right {
            height: 100%;
            // flex:2;
            display: flex;
            align-items: center;
            // background: green;

            .nav_items {
              height: 100%;

              // background: red;
              .el-menuMe {
                background: transparent;
                height: 100%;

              }

              .el-menu--horizontal>.el-menu-item {
                color: #fff;
                height: 100%;
                display: flex;
                align-items: center;
                // background: purple;
                border: none;
                border-top: 3px solid transparent;
                font-size: 16px;
                margin-right: 20px;

              }

              .el-submenu__title i {
                color: #fff;
              }

              .el-menu.el-menu--horizontal {
                border: none;
              }

              // 移入导航情况
              .el-menu--horizontal>.el-menu-item:hover {
                background: transparent;
                // color:red
              }

              .el-menu--horizontal>.el-menu-item {
                // transition: all .35s ;
              }

              .el-menu--horizontal>.el-menu-item.is-active {
                border-top: 3px solid #fff;
                border-bottom: none;
                height: 100%;

                // background: greenyellow;
                //  transform: translateX(-15%);
              }

              // 我们的服务
              .el-submenu {
                height: 100%;

              }

              .el-menu--horizontal>.el-submenu .el-submenu__title {
                border: none;
                border-top: 3px solid transparent;
                color: #fff;
                // background: red;
                height: 100%;
                display: flex;
                align-items: center;
                margin-right: 20px;

              }

              .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
                background: transparent;
                // background: red;
              }

              .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
                border-top: 3px solid #fff;
                border-bottom: none;
                height: 100%;
                // background: red;

              }

              .el-menu--horizontal>.el-submenu .el-submenu__title {
                font-size: 16px;
                // background: red;

              }

              // 我们的服务下拉
            }

            .websiteIconBtn {
              margin-left: 10px;

              .elButt {
                // display: flex;
                background: #2e67b1;
                border-radius: 20px;
                padding: 7px 17px;
                box-sizing: border-box;
                border: none;

                .iconIMg {
                  width: 24px;
                  height: 24px;
                  // background: orange;
                  border-radius: 20px;
                  margin-right: 10px;
                }

                &>span {
                  display: flex;
                  align-items: center;
                  // background: red;
                }
              }
            }
          }
        }
      }

      .newTitle {
        height: 300px;
        // background: orange;
        position: absolute;
        top: 0;
      }

      // 新闻中心
      .news_pages_title {
        width: 1200px;
        height: 100px;
        // background:red;
        position: absolute;
        bottom: -15%;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      // 其他页面
      .pages_title {
        width: 1200px;
        height: 110px;
        // background:red;
        position: absolute;
        bottom: -55px;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      .pages_title_l {
        min-width: 280px;
        height: 110px;
        color: #fff;
        line-height: 100px;
        display: flex;
        align-items: center;
        text-align: left;
        padding-left: 24px;
        box-sizing: border-box;
        padding-right: 24px;
        background: #2e67b1 linear-gradient(270deg, rgba(3, 0, 0, 0.15), rgba(255, 255, 255, 0.15));
      }

      .pages_title_r {
        margin-bottom: 18px;

        .breadCrumb {
          display: flex;
          align-items: center;

          .homeImg {
            margin-right: 10px;
          }
        }
      }

      .index_title {
        width: 1200px;
        height: 180px;
        // background:greenyellow;
        position: absolute;
        // bottom: -50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // transform: translateY(-50%);
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        z-index: 2;

        .el-carousel {
          width: 100% !important;
          // height: 154px;
          // background: orange!important;
          overflow-y: hidden;
          overflow-x: hidden;
        }

        .el-carousel__container {
          width: 100%;
          // height: 154px;
          // background: gray;
        }

        .el-carousel__item {
          display: flex;
          // justify-content: space-between;
          justify-content: space-around;
        }

        // 首页第一张banner内容
        .index_title_items1 {
          width: 154px;
          height: 154px;
          // background: orange;
          margin-left: 20px;
          margin-top: 15px;
          border-radius: 6px;
          transition: all 0.15s linear;
          cursor: pointer;
          //  box-shadow :  0 4px 6px 0px  #dce1e7;
          box-shadow: 0px 5px 8px -2px #d6dae3;

          &:nth-child(1) {
            margin-left: 0px;
          }

          &:hover {
            transform: translateY(-15px);
          }
        }

        // 首页第二张banner内容
        .index_title_items2 {
          width: 285px;
          height: 154px;
          // background: orange;
          transition: all 0.15s linear;
          cursor: pointer;
          margin-top: 15px;
          // box-shadow :  0 4px 6px 0px  #dce1e7;
          box-shadow: 0px 5px 8px -2px #d6dae3;

          img {
            width: 100%;
            height: 100%;
          }

          &:hover {
            transform: translateY(-15px);
          }
        }

        .index_title_items3 {
          cursor: pointer;
          width: 385px;
          height: 154px;
          // background: red;
          transition: all 0.15s linear;
          margin-top: 15px;
          //  box-shadow :  0 2px 5px 0px  #dce1e7;
          box-shadow: 0px 5px 8px -2px #d6dae3;

          img {
            width: 100%;
            height: 100%;
          }

          &:hover {
            transform: translateY(-15px);
          }
        }
      }
    }
  }

  .el-menu {
    --el-menu--popup: 220px !important;
  }

  // 我的服务下拉二级和三级效果
  .el-submenu__title i {
    line-height: 16px !important;
  }

  .el-menu--horizontal .el-menu .el-menu-item,
  .el-menu--horizontal .el-menu .el-submenu__title {
    height: 44px;
    line-height: 44px;
  }

  .el-menu {
    --el-menu--popup: 150px;
  }

  .el-menu--popup {
    max-width: var(--el-menu--popup) !important;
    min-width: var(--el-menu--popup) !important;
  }

  .el-submenu .el-menu-item {
    max-width: var(--el-menu--popup) !important;
    min-width: var(--el-menu--popup) !important;
  }

  .el-menu--popup-bottom-start {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    padding: 15px 0;
    box-sizing: border-box;
  }

  .el-menu--horizontal .el-menu .el-menu-item.is-active,
  .el-menu--horizontal .el-menu .el-submenu.is-active>.el-submenu__title {
    color: #3e72b7;
    font-weight: 900;
    background: #e0e8f3;
  }

  .el-menu--popup-right-start {
    border-radius: 8px;
    text-align: center;

    // width: 30px;
  }

  // 当前页面标题父级路由的hover
  .el-breadcrumb__inner a:hover,
  .el-breadcrumb__inner.is-link:hover {
    color: #2e67b1;
  }
</style>
